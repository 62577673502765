import React from "react";
import style from './index.module.less'
import { Layout, SEO } from '@/components/index'
import { useIntl } from "react-intl";

const amazonLogo =process.env.resourceUrl + 'images/distributors/distributors_logo_amazon.png'
const smartechLogo =process.env.resourceUrl + 'images/distributors/distributors_logo_smartech.png'
const BicCameraLogo =process.env.resourceUrl + 'images/distributors/distributors_logo_BicCamera.webp'
const icoreLogo =process.env.resourceUrl + 'images/distributors/distributors_logo_icore.webp'
const yodobashiLogo =process.env.resourceUrl + 'images/distributors/distributors_logo_yodobashi.webp'
const innproLogo =process.env.resourceUrl + 'images/distributors/distributors_logo_innpro.png'
const microLogo =process.env.resourceUrl + 'images/distributors/distributors_logo_micro.png'
const Index = () => {
    const intl = useIntl()
    const list=[
        {
            area:intl.formatMessage({ id: "distributor_area1" }),
            support:[
                {
                    type:"amazon",
                    link:"https://www.amazon.co.jp/-/zh/stores/XREAL/page/D6932B80-A5E2-45AC-AEB2-6A258CD9B859?ref_=ast_bln"
                },
                {
                    type:"yodobashi",
                    link:"https://www.yodobashi.com/?word=xreal"
                },
                {
                    type:"BicCamera",
                    link:"https://www.biccamera.com/bc/category/?q=xreal"
                },
            ]
        },  {
            area:intl.formatMessage({ id: "distributor_area2" }),
            support:[
                {
                    type:"smartech",
                    link:"https://smartechwrld.com/"
                }, {
                    type:"amazon",
                    link:"https://www.amazon.co.uk/stores/XREAL/page/AA1EB0FF-9B71-4AD2-8812-20B0E2E82BDF?ref_=ast_bln"
                }, {
                    type:"innpro",
                    link:"https://innpro.eu/value-added-distributor/"
                }
            ]
        },  {
            area:intl.formatMessage({ id: "distributor_area3" }),
            support:[
                {
                    type:"amazon",
                    link:"https://www.amazon.com/sp?ie=UTF8&seller=A1MP9UBTN7Z9V&asin=B0CHVQWW4P&ref_=dp_merchant_link&isAmazonFulfilled=1"
                },{
                    type:"micro",
                    link:"https://www.microcenter.com/"
                }
            ]
        },  {
            area:intl.formatMessage({ id: "distributor_area4" }),
            support:[
                {
                    type:"amazon",
                    link:"https://www.amazon.ae/XREAL-Electrochrome-Darkening-Compatible-SteamDeck/dp/B0CHVZL1FR/ref=zg_bs_g_12303865031_d_sccl_1/261-5403004-3451912?psc=1"
                },
                {
                    type:"icore",
                    link:"https://www.icoreme.com/home"
                }
            ]
        }
    ]
    const logolist={
        amazon:amazonLogo,
        smartech:smartechLogo,
        icore:icoreLogo,
        yodobashi:yodobashiLogo,
        BicCamera:BicCameraLogo,
        innpro:innproLogo,
        micro:microLogo
    }
    return (
        <Layout menuBlack={true}>
            <SEO page="Retailers & Distributors" />
            <div className={style.box}>
                <div className={style.title}>
                    {intl.formatMessage({ id: "distributor_title" })}
                </div>
                <div className={style.list}>
                    {list.map((item,index)=>(
                        <div className={style.listItem} key={`distributors_item${index}`}>
                            <div className={style.listItemTitle}>
                                {item.area}
                            </div>
                            <div className={style.listItemLogo}>
                                {item.support.map((value,key)=>(
                                    <div className={style.listItemLogoBox} key={`distributors_item${index}_${key}`} 
                                    onClick={()=>window.open(value.link)}
                                    >
                                        <img src={logolist[value.type]}/>
                                     
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export default Index